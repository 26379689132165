import React, { Suspense, lazy, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Cookies from "js-cookie";
import styled from "@emotion/styled";
import { Global } from "@emotion/core";

import globalStyle from "./styles/globalStyle";

import Sidebar from "./Sidebar/Sidebar";
import Topbar from "./Topbar/Topbar";

import CompanyProvider from "./providers/CompanyProvider";

import Loading from "./shared/Loading";
import CountriesProvider from "./providers/CountriesProvider";
import TokenProvider from "services/http/TokenProvider";
import { Query } from "components/RequestsManager";
import Loader from "services/http/TokenProvider/Loader";
import i18n from "i18n";
import RightsProvider from "./providers/RightProvider";

const CampaignsInPreparation = lazy(() =>
  import("./CampaignsInPreparation/CampaignsInPreparation")
);
const CampaignsProgrammed = lazy(() =>
  import("./CampaignsProgrammed/CampaignsProgrammed")
);
const Home = lazy(() => import("./Home/Home"));
const NewCampaign = lazy(() => import("./NewCampaign/NewCampaign"));
const Statistics = lazy(() => import("./Statistics/Statistics"));
const Blacklist = lazy(() => import("./Blacklist/Blacklist"));
const Unauthorized = lazy(() => import("./Unauthorized"));
const NoMatch = lazy(() => import("./NoMatch"));
const AccessDenied = lazy(() => import("./AccessDenied"));

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;

  main {
    flex: 1;

    display: flex;
    flex-direction: column;
  }
`;

const {
  REACT_APP_APPLICATION_ID,
  REACT_APP_SSO_URL,
  REACT_APP_MY_LOGIN,
} = process.env;

const defaultValues = {
  LastConnexionLanguage: "fr",
};

const App: React.FC = () => {
  const [hasAccess, setHasAccess] = useState(false);

  return (
    <>
      <TokenProvider
        endpoint={REACT_APP_SSO_URL}
        appId={REACT_APP_APPLICATION_ID}
        redirectUrl={`${REACT_APP_MY_LOGIN}?brand_id=${REACT_APP_APPLICATION_ID}&redirected=true&lang=FR&returnUrl=${encodeURIComponent(
          window.location.href
        )}`}
      >
        <Query
          endpoint={`${process.env.REACT_APP_SSO_URL}api/user/preferences`}
          token={Cookies.get("at")}
        >
          {({ data, error, isLoading }: any) => {
            if (isLoading) return <Loader />;

            const { LastConnexionLanguage } = (!error && data) || defaultValues;

            if (
              LastConnexionLanguage &&
              i18n.language.toLowerCase() !==
                LastConnexionLanguage.toLowerCase()
            )
              i18n.changeLanguage(LastConnexionLanguage.toLowerCase());

            return (
              <Query
                endpoint={`${process.env.REACT_APP_API_URL}user/access`}
                token={Cookies.get("at")}
              >
                {({ data, error, isLoading }: any) => {
                  if (isLoading) return <Loader />;
                  if (error) {
                    return <Redirect to={"/accessdenied"} />;
                  }
                  if (data.result.companyHasBdd && data.result.hasAccess) {
                    setHasAccess(true);
                  }
                  return (
                    <React.Fragment>
                      <Global styles={globalStyle} />
                      <CompanyProvider>
                        <CountriesProvider>
                          <RightsProvider>
                            <Container>
                              <Router>
                                <Suspense fallback={<Loading />}>
                                  {hasAccess && <Sidebar />}
                                  <main>
                                    {hasAccess && <Topbar />}
                                    {!hasAccess && (
                                      <Redirect to={"/accessdenied"} />
                                    )}

                                    <Switch>
                                      <Route
                                        exact
                                        path="/"
                                        render={() => {
                                          return hasAccess ? (
                                            <Redirect to="/home" />
                                          ) : (
                                            <Redirect to="/accessdenied" />
                                          );
                                        }}
                                      />
                                      <Route exact path="/home">
                                        <Home />
                                      </Route>
                                      <Route path="/new-campaign/:step?">
                                        <NewCampaign />
                                      </Route>
                                      <Route path="/campaign/:id?/:step?">
                                        <NewCampaign />
                                      </Route>
                                      <Route path="/in-preparation-campaign">
                                        <CampaignsInPreparation />
                                      </Route>
                                      <Route path="/scheduled-campaign">
                                        <CampaignsProgrammed />
                                      </Route>
                                      <Route path="/statistics">
                                        <Statistics />
                                      </Route>
                                      <Route path="/blacklist">
                                        <Blacklist />
                                      </Route>
                                      <Route path="/unauthorized">
                                        <Unauthorized />
                                      </Route>
                                      <Route path="/accessdenied" exact>
                                        <AccessDenied />
                                      </Route>
                                      <Route>
                                        <NoMatch />
                                      </Route>
                                    </Switch>
                                  </main>
                                </Suspense>
                              </Router>
                            </Container>
                          </RightsProvider>
                        </CountriesProvider>
                      </CompanyProvider>
                    </React.Fragment>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </TokenProvider>
    </>
  );
};

export default App;
