import Cookies from "js-cookie";
import axios from "axios";

const {
  REACT_APP_APPLICATION_ID,
  REACT_APP_BASE_URL,
  REACT_APP_MY_LOGIN,
  REACT_APP_LOGIN_API_URL,
} = process.env;

export function redirectToLoginPage() {
  const daid = Cookies.get("daid") !== undefined ? Cookies.get("daid") : "";

  window.location.href = `
    ${REACT_APP_MY_LOGIN}?returnUrl=${REACT_APP_BASE_URL}login_callback&brand_id=${REACT_APP_APPLICATION_ID}&daid=${daid}`;
}

export function redirectToLogoutPage() {
  window.location.href = `${REACT_APP_MY_LOGIN}?brand_id=${REACT_APP_APPLICATION_ID}&redirected=true&lang=FR&returnUrl=${encodeURIComponent(
    window.location.href
  )}`;
}

export const logout = async () => {
  axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {},
    url: `${REACT_APP_LOGIN_API_URL}api/Auth/logout`,
  });
};

export function handleDisconnect() {
  Cookies.remove("at", { domain: ".mindbaz.com" });
  Cookies.remove("rt", { domain: ".mindbaz.com" });
  Cookies.remove("rm", { domain: `.mindbaz.com` });
  logout();
  redirectToLogoutPage();
}
